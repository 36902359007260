import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../../components/image.js";
export const query = graphql`
  query {
    navmotor: file(relativePath: { eq: "cykel_navmotor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
    mittmotor: file(relativePath: { eq: "cykel_mittmotor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "motorplacering"
    }}>{`Motorplacering`}</h1>
    <p>{`Motorn är en av de två viktigaste komponenterna i en elcykel, där den
andra är batteriet, läs mer under `}<Link to="/guider/elcykelbatterier/" mdxType="Link">{`batterier`}</Link>{`. Det finns olika typer
av motorer, som beror på placering av motorn. Antingen placeras motorn
vi tramporna och kallas då mittmotor, eller så placeras motorn i navet
i antingen framhjulet eller bakhjulet och kallas då navmotor. Kort
sagt kan man säga att en mittmotor ge bättre känsla och är mer
effektiv, men samtidigt i regel också dyrare. En navmotor däremot är
billigare men kan vara lite ryckigare och prestera lite sämre.`}</p>
    <p>{`I guiden nedan går vi igenom både fördelar och nackdelar för de olika
typerna för att hjälpa dig att välja rätt. Tänk på att i Sverige och
EU gäller en maxgräns på 250W samt att motorn enbart får hjälpa till
upp till 25km/h så länge man trampar manuellt. Vid högre effekt och
hastighet krävs bland annat förarbevis, hjälp, försäkring m.m. En stor
majoritet av cyklarna som säljs i Sverige håller sig inom dessa
gränser, och gör de inte det brukar detta anges.`}</p>
    <h2 {...{
      "id": "navmotor"
    }}>{`Navmotor`}</h2>
    <p>{`En navmotor består av en motor som är placerat i navet i ett av
hjulen. Detta kan vara i framhjulet eller i bakhjulet. Då framhjulet
är enklare att byta på en cykel kan det vara enklare att placera
motorn där om man gör om en gammal cykel till en elcykel. Köper man en
ny, färdig, elcykel är motorn dock i regel placerad i bakhjulet då
detta medför flera fördelar, bland annat mindre risk för sladd och
bättre stabilitet.`}</p>
    <p>{`En navmotor är i regel en relativ enkel konstruktion som har få
rörliga delar, dock kan de ha inbyggda växlar för att kunna utnyttja
motorn bättre, även om detta är ganska ovanligt. Med hjälp av en
kadenssensor så känner motorn av när du börjar trampa, och slår då på
motorn. När du sedan slutar trampa känner sensorn även av detta och
slår då av motorn. Styrkan på motorn kan sedan regleras via knappar
eller en display på styret.`}</p>
    <div className="reponsive_image">
  <Image fixed={props.data.navmotor.childImageSharp.fixed} alt="Navmotor" title="Navmotor" rowOne="En cykel med en navmotor i framhjulet. Navmotorn kan även placeras i bakhjulet." rowTwo="Licens: Creative Commons Attribution-Share Alike 3.0 Unported" rowThree="Källa: Mikefairbanks / Wikimedia Commons." mdxType="Image" />
    </div>
    <h3 {...{
      "id": "fördelar-med-navmotor"
    }}>{`Fördelar med navmotor`}</h3>
    <p>{`Som nämndes ovan så är en navmotor en relativt enkel konstruktion med
få rörliga delar. Detta gör att även att de inte kräver så mycket
underhåll samt inte går sönder så lätt. Att motorn är integrerad i
hjulet och isolerad från övriga cykeln gör också att man enkelt kan
plocka av hela hjulet för att lämna in på service eller byta ut.`}</p>
    <p>{`En annan fördel, jämfört med mittmotor, är att kraften överförs direkt
till hjulet utan att gå via kedja och växlar. Detta gör att en
navmotor inte medför extra slitage på kedja och växlar, tvärtom kan
det medföra mindre slitage jämfört med en traditionell cykel så man på
en elcykel inte behöver trycka på lika hårt när man trampar.`}</p>
    <p>{`Sist men inte minst så är en stor fördel priset, vilket gör att många
väljer cyklar med denna typ av motor. Den enkla konstruktionen
tillsammans med att en navmotor inte kräver några större ingrepp på
resten av cykeln gör att dessa kan massproduceras i tusentals, även
miljontals i vissa fabriker vilket har pressat ner priserna rejält.
Hos de billigare elcyklarna kan man nästan vara säker på att det är en
navmotor.`}</p>
    <h3 {...{
      "id": "nackdelar-med-navmotor"
    }}>{`Nackdelar med navmotor`}</h3>
    <p>{`Den enkla konstruktionen medför tyvärr en del nackdelar. Även om det
är en fördel att en navmotor inte utnyttjar cykelns växlar kan detta
även vara en stor nackdel. En oväxlad motor medför att den får svårt
att driva på i uppförsbackar eller från stillastående, vilket gör att
den drar mer batteri samt att det går långsammare då motorn är som
mest effektiv när den får snurra lite fortare. Hade man haft växlar
hade man kunnat växla ner och låta motorn driva på hög hastighet medan
cykeln gick långsamt. Vissa navmotorer har integrerade växlar för att
lösa detta problem men det är relativt ovanligt då det medför mer
underhåll samt en dyrare produkt.`}</p>
    <p>{`En annan nackdel, jämfört med mittmotorer, är att de är tyngre.
Framförallt är vikten "odämpad" vilket betyder att den sitter på den
odämpade delen av cykeln. På en dämpad cykel så sitter dämpningen
mellan hjul och ram, och allt som sitter i hjulen är då odämpat och
allt som sitter i ramen är dämpat. På helt odämpade cyklar spelar
detta dock mindre roll då även en mittmotor i så fall är odämpad. Då
återstår enbart nackdelen att den är tyngre.`}</p>
    <p>{`Utöver avsaknaden av växlar och vikten så begränsar även navmotoron
dig i dina val av hjul, däck och växelkasett. Då motorn är en
integrerad del av hjulet kan du inte byta hjul utan att även byta
motor. En navmotor gör även hjulet lite bredare vilket begränsar dig i
valet av växelkasett då de inte får vara för breda. Det blir även
svårare att laga punkteringar på det hjul som motorn sitter på då
motorn måste kopplas loss från batteriet för att få loss hjulet.`}</p>
    <p>{`Sist men inte minst så har en navmotor en så kallad kadenssensor för
att avgöra om du trampar eller inte. En kadenssensor kan jämföras med
en strömbrytare som, när du börjar trampa, slås på och motorn börjar
då att driva. Dock kan en kadenssensor inte känna av hur hårt du
trampar, så styrkan på motorn måste istället styras via knappar eller
en display på styret. En kadenssensor reagerar dessutom inte så snabbt
och från det att du börjar trampa kan det kanske dröja någon eller
några sekunder innan motorn slås på, detsamma gäller när du slutar
trampa och motorn slås av. Detta kan leda till något sämre
cykelkänsla.`}</p>
    <h3 {...{
      "id": "skillnader-mellan-navmotor-fram-och-navmotor-bak"
    }}>{`Skillnader mellan navmotor fram och navmotor bak`}</h3>
    <p>{`En skillnad mellan att placera navmotoron i framhjulet eller i
bakhjulet är viktfördelningen på cykeln. Har man motorn i bakhjulet
kommer cykeln att bli lite baktung, men om man placerar motorn i
framhjulet kommer den i stället att bli lite mer framtung. På många
elcyklar placeras dock batteriet vid pakethållaren på cykeln vilket
lägger på mer vikt där bak, och då kan det ibland vara smart att ha
motorn i framhjulet för att kompensera för detta och jämna ut vikten
då man vill ha cykelns tyngdpunkt så nära mitten som möjligt för att
underlätta balansen vid cyklandet.`}</p>
    <p>{`Något annat man också kan tänka på är att det är vanligare med
punktering i backdäcket jämfört med framdäcket. Detta då t.ex. glas
eller annat som ligger på vägen ofta ligger ganska platt och
framhjulet kan rulla över utan att få punktering. Dock kan framhjulet
"skvätta upp" glaset som då inte ligger lika platt när bakhjulet kör
över vilket lättare leder till punktering. Ett hjul utan motor är
enklare att plocka av och laga punkteringen på, vilket talar för att
ha motorn i framhjulet. Det är däremot inte jätte komplicerat att
koppla ur motorn så det är en marginell fördel med att ha den i
framhjulet.`}</p>
    <p>{`Den största skillnaden som nog är viktigast för de flesta, är att
oavsett cykelns konstruktion så ligger mer vikt på bakdäcket då detta
i regel driver på cykeln. Mer vikt på bakdäcket gör att detta har
högre friktion med marken och riskerar då inte att slira och sladda
lika enkelt. Placerar man motorn i framhjulet så får man mycket
enklare sladd om man kör på halt underlag såsom löv, is eller grus.
Denna skillnad gör att de flesta väljer en cykel med motorn i
bakhjulet.`}</p>
    <p>{`Det finns även andra, lite mindre skillnader. Till exempel är
framgaffeln i regel lite svarare än infästningen för bakhjulet och en
tung motor sitter därför bättre där bak. Men en med en motor på 250W
så är det ofta inga problem för framgaffeln att klara detta. Många
tycker, även om det är en subjektiv åsikt, att det är skönare med att
bakhjulet "puttar på" jämfört med att framhjulet "drar en framåt".`}</p>
    <p>{`På de flesta cyklar du hittar i handeln med navmotor så sitter den i
bakhjulet, framhjulsdrivna elcyklar är framförallt vanligare hos
personer som själva konverterar gamla cyklar till elcyklar, då
framhjulet är enklast att byta ut.`}</p>
    <h2 {...{
      "id": "mittmotor"
    }}>{`Mittmotor`}</h2>
    <p>{`Mittmotorer har designats för att rätta till flera av de nackdelarna
som finns med navmotorer. Konstruktionen består av att man placerar en
motor vid tramporna och som sedan hjälper till att driva på tramporna,
och via kedjan och växlarna även hjulet. Mittmotorer kan vara väldigt
små och nätta, så pass små att man ibland inte ens kan se att en cykel
är en elcykel, bortsett från batteriet. Mittmotorer är i regel ganska
mycket dyrare och det har historiskt sett varit svårt att hitta en
elcykel med mittmotor för under 20 000kr, även om priset nu börjar
krypa neråt i takt med att populariteten ökar.`}</p>
    <p>{`För att förenkla för dig som vill köpa en cykel med mittmotor har vi skapat en sida med utvalda cyklar med mittmotor. Dessa hittar du under `}<Link to="/cyklar/mittmotor/" mdxType="Link">{`Cyklar - Mittmotor`}</Link>{`. Där kan du också filtrera på andra egenskaper så som räckvidd, antal växlar och pris.`}</p>
    <div className="reponsive_image">
  <Image fixed={props.data.mittmotor.childImageSharp.fixed} alt="En cykel med mittmotor" title="En cykel med mittmotor" rowOne="En cykel med en mittmotor. Motorn syns som en liten extra enhet vid tramporna." rowTwo="Licens: Creative Commons Attribution-Share Alike 3.0 Unported" rowThree="Källa: Clément Bucco-Lechat / Wikimedia Commons." mdxType="Image" />
    </div>
    <h3 {...{
      "id": "fördelar-med-mittmotor"
    }}>{`Fördelar med mittmotor`}</h3>
    <p>{`En stor fördel med mittmotor är att den driver cykeln via cykelns
växlar. Detta gör att när du växlar ner för att trampa upp för en
backa, så växlar det även ner för motorn som då kan driva med lite
högre hastighet och då vara mer effektiv. Det samma gäller när du
startar från stillastående. När motorn får driva på en högre
hastighet, via cykelns växlar, så utnyttjas batteriet mer och du
kommer längre på en laddningscykel jämfört med en navmotor.`}</p>
    <p>{`Som vi nämnde ovan så är mittmotorer relativt små och smidiga, och kan
dessutom ofta integreras rätt in i ramen. Detta gör att inga ingrepp
behöver göras på cykelns växlar eller hjul och användaren kan fritt
välja om denne vill byta ut dessa. Detta gör dessutom att det blir
enklare att laga punkteringar då man inte behöver ta hänsyn till någon
motor som behöver kopplas loss.`}</p>
    <p>{`En annan fördel, som många ser som en väldigt viktig, är att
mittmotorer använder sig utav vridmomentssensorer, istället för
kadenssensorer som för navmotorer, för att avgöra om du trampar. Dessa
reagerar väldigt snabbt och så fort du börjar eller slutar trampa så
kommer motorn att börja eller sluta drive. Inte som med navmotorer där
det kan dröja någon eller några sekunder innan motorn reagerar.
Dessutom kan vridmomentssensorer känna av hur hår du trampar och kan
då automatiskt reglera hur hårt motorn ska driva utan att du behöver
ställa in detta via knappar på styret. Just vridmomentssensorn och
dess funktion gör att många tycker att det är mer känsla när man
cyklar med en mittmotor jämfört med en navmotor.`}</p>
    <h3 {...{
      "id": "nackdelar-med-mittmotor"
    }}>{`Nackdelar med mittmotor`}</h3>
    <p>{`Trots alla fördelar med mittmotorer så har de även en del nackdelar.
En av dessa är att det blir markant mycket mer slitage på både kedja
och växlar. Framförallt kan man märka detta på kedjan som kommer
behöva bytas oftare. Man kan jämföra det med en icke-elcykel där en
vanlig person kanske trampar med 100W kontinuerligt och 250W i kortare
stunder. En elmotor driver kan istället driva med 250W konstant under
långa tider vilket sliter mycket på kedjan. Detta gör att elcyklar med
mittmotor ofta har en förstärkt kedja, vilket man också bör tänka på
om man ska byta kedja.`}</p>
    <p>{`En mittmotor har dessutom fler rörliga delar vilket betyder att fler
saker kan gå sönder. Dessutom gör detta att motorn behöver mer
service, service som dessutom är svårare att utföra. Tillsammans med
att mittmotorer är dyrare och kan komma i fler former och storlekar så
blir det också dyrare och mer komplicerat om man skulle behöva byta ut
den.`}</p>
    <p>{`Sist men inte minst så är mittmorer betydligt dyrare än navmotorer.
Detta då det är fler rörliga delar samt att de kommer i fler former
och storlekar och då inte massproduceras i samma skala.`}</p>
    <h2 {...{
      "id": "slutsats"
    }}>{`Slutsats`}</h2>
    <p>{`Så slutsatsen är tyvärr att valet av motor beror på. En navmotor har
sina fördelar, och en mittmotor har sina. Men överlag kan man säga att
en mittmotor är att föredra i de flesta lägen, om plånboken tillåter.
Att mittmotorn kräver mer underhåll och sliter mer på en kedja
påverkar marginellt då underhållet ändå är ganska begränsat och
kedjebyte relativt enkelt.`}</p>
    <p>{`Men, vet du med dit att du till exempel ska köra off-road eller i
mycket backar så kan en cykel med mittmotor vara värt de extra
pengarna så du kan utnyttja batteriet bättre och få en bekvämare
cykling.`}</p>
    <p>{`Har du däremot en lite kortare pendling till jobbet och vill ha en
cykel för att slippa komma fram svettig, ja då kanske en cykel med
navmotor är tillräckligt. Navmotorer har funnits länge är väl
beprövade. Dessutom slipper du för mycket underhåll.`}</p>
    <p>{`Men vi brukar alltid rekommendera att man provcyklar båda varianterna!
Du kommer snabbt känna skillnaden mellan vridmomentssensorn i
mittmotorn och kadenssensorn i navmotorn. Vissa tycker sedan att det
inte spelar någon roll, medan vissa absolut inte klarar av känslan med
en kadenssensor.`}</p>
    <p>{`Vi hoppas i alla fall att du har fått lite mer kött på benen och att
du kan smala av din filtrering av elcyklar ytterligare för att
underlätta ditt val.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      